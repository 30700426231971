<template>
  <div>
    <div class="content_box beforetab capture_area">
      <!-- content_box  -->
      <div class="clearfix">
        <div class="col_6">
          <p class="text_desc">
            {{ $t('msg.CART010G010.068') }}
          </p>
          <br>
          <div class="graph_area" style="margin:0px;width:770px;">
            <!-- chart 영역  화면 줄였을때 width:250px; 가 적당 -->
            <canvas id="myChart"></canvas>
            <div id="chartjs-tooltip"></div>
          </div><!-- chart 영역 // -->
        </div><!-- right  // -->
      </div>
    </div><!-- cpntent_box // -->
    <div id="pdf_area"></div>
  </div><!-- content // -->
</template>

<script>
import { Doughnut, Line } from 'vue-chart.js'
import { rootComputed } from '@/store/helpers'

let myChart
export default {
  name: 'RfDataGraph',
  components: {},
  extends: Line,
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          // fromDt: '',
          // toDt: ''
        }
      }
     }
  },
  data: function () {
    return {
      customComponent: null,
      expired: '', //(Expired)
      limitInfo: { },
      amtInfo: { },
      strDt: '',
      endDt: '',
      labels: [],
      setPointData: [],
      currentData: [],
      humidityData: [],
      rfGraphList: []
    }
  },
  computed: {
    ...rootComputed,
    rfGraphDataList () {
      return this.$store.getters['trans/getRfGraphDataList']
    }
  },
  watch: {
    rfGraphDataList: {
      handler (val) {
        // console.log('RfdataGraph parentInfo @@@@@ ', this.parentInfo)

        this.searchData()
      },
      deep: true
    }
  },
  created () {
    window.functionOutsideVue(this)
    // console.log('RfdataGraph parentInfo @@@@@ ', this.parentInfo)
  },
  mounted: function () {
    window.vmApp = this
    this.searchData()
    // this.createChart()
  },
  methods: {
    searchData () {
      this.createChart()
    },
    createChart () {
      if (myChart !== undefined) {
        console.log('destroy @@@@@')
        myChart.destroy()
      }

      this.labels = []
      this.setPointData = []
      this.currentData = []
      this.humidityData = []

      // console.log('rfGraphDataList @@@ ', this.rfGraphDataList)

      const graphData = this.rfGraphDataList

      this.rfGraphDataList.forEach(ele => {
        const cntrDtm = (ele.cntrDtm.substring(0, 10) + ' ' + ele.cntrDtm.substring(11, 16))
        this.labels.push(cntrDtm)
        this.setPointData.push(ele.tSet)
        this.currentData.push(ele.trtn1)
        this.humidityData.push(ele.pctHum)
      })
      let ctx = document.getElementById('myChart')

      console.log('차트시작~~')
      let config = {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: [{
                      label: 'Set Point',
                      yAxisID: 'A',
                      borderColor: 'rgba(255, 255, 0, 0.7)',
                      backgroundColor: 'white',
                      data: this.setPointData,
                      fill: false
                      }, {
                      label: 'Current T',
                      yAxisID: 'B',
                      borderColor: 'rgba(000, 102, 255)',
                      backgroundColor: 'white',
                      data: this.currentData,
                      fill: false
                      }, {
                      label: 'Humidity',
                      yAxisID: 'C',
                      borderColor: '#c62828',
                      backgroundColor: 'white',
                      data: this.humidityData,
                      fill: false
                      }]
        },
        options: {
            responsive: true,
            tooltips: {
              bodyFontSize: 12,
              bodySpacing: 0,
              enabled: true,
              mode: 'index'
            },
            scales: {
              display: true,
              xAxes: [
                {
                  display: true, /* xAxes 라벨 표시 */
                  gridLines: {
                    borderDash: [],
                    borderDashOffset: 0,
                    // color: rgba(0, 0, 0, 0.1),
                    display: true, /* xAxes의 gridLine 표시 */
                    drawBorder: false,
                    drawTicks: true,
                    lineWidth: 1,
                    offsetGridLines: false,
                    tickMarkLength: 10,
                    zeroLineBorderDash: [],
                    zeroLineBorderDashOffset: 0,
                    // zeroLineColor: rgba(0, 0, 0, 0.1),
                    zeroLineWidth: 1
                  },
                  id: 'x_' + 0,
                  offset: false,
                  // position: bottom,
                  scaleLabel: { /* xAxes의 서브타이틀 */
                    display: false,
                    labelString: 'X축 입니다.',
                    padding: {
                      bottom: 4,
                      top: 4
                    }
                  },
                  ticks: {
                    autoSkip: true,
                    autoSkipPadding: 0,
                    beginAtZero: true, /* 0부터 시작 */
                    // callback: ƒ(value),
                    display: true, /* xAxes 라벨 표시 */
                    labelOffset: 0,
                    major: { /* 주 눈금. 생략된 옵션은 위의 옵션에서 상속 */
                      /* major와 minor의 옵션이 같아서 여기에만 작성함. */
                      // callback: (),
                      fontColor: '#666',
                      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                      fontSize: 12,
                      fontStyle: 'normal'
                      //lineHeight: 1.2
                    },
                    maxRotation: 50,
                    minRotation: 0, /* xAxes 라벨 글씨 최소 기울기 */
                    minor: {}, /* 보조 눈금. 생략된 옵션은 위의 옵션에서 상속 */
                    padding: 0,
                    reverse: false, /* xAxes 순서 반대로 */
                    fontSize: 9,
                    fontColor: '#000000'
                  }
                  // type: category
                }
              ],
              yAxes: [
              {
                id: 'A',
                type: 'linear',
                position: 'left',
                tilte: 'Temperature',
                display: false,
                ticks: {
                  min: -30,
                  max: 50,
                  stepSize: 20,
                  fontColor: '#91cf96',
                  callback: function (value, index, values) {
                    return value + ' °C'
                  }
                }
              },
               {
                id: 'B',
                type: 'linear',
                position: 'left',
                ticks: {
                  min: -30,
                  max: 50,
                  stepSize: 20,
                  fontColor: '#1b5e20',
                  callback: function (value, index, values) {
                    return value + ' °C'
                  }
                }
              }, {
                id: 'C',
                type: 'linear',
                position: 'right',
                ticks: {
                  min: 0,
                  max: 120,
                  stepSize: 20,
                  fontColor: '#4527A0',
                  callback: function (value, index, values) {
                    return value + ' %'
                  }
                }
              }]
            },
            elements: {
              line: {
                tension: 0 // disables bezier curves
              },
              point: {
                pointStyle: 'circle',
                radius: 2,
                borderWidth: 1
              }
            }
          }
      }
      myChart = new Chart(ctx, config)
      myChart.update()
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    closeFun () {
      this.$Simplert.close()
    },
    openPopup (compNm, kind) {
      // console.log('openPopup')
      this.customComponent = compNm

      $('#layerPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    closePoup () {
      this.isShowPop = false
      $('#layerPop > .popup_dim').fadeOut()
      $('body,html').removeAttr('style')
    },
    screenPrint () {
      const ele = document.querySelector('.graph_area')
      this.$ekmtcCommon.elemAddClass(ele, 'screen_shot')

      const options = {
        cssSelector: '.graph_area'
      }

      this.$ekmtcCommon.screenToPrint(options).then(res => {
        if (res === undefined || res === null) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.244'))
        }
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      }).catch(() => {
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      })
    }
  }
}

</script>

<style scoped>
  .text-column {
    text-align: left;
  }
  .number-column {
    text-align: right;
  }

  .text_desc {
    color: #777777;
    font-size: 13px;
    width: 550px;
  }

  #chartjs-tooltip {
      opacity: 1;
      position: absolute;
      background: rgba(0, 0, 0, .7);
      color: white;
      border-radius: 3px;
      -webkit-transition: all .1s ease;
      transition: all .1s ease;
      pointer-events: none;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      z-index: 9999999;
    }
    .chartjs-tooltip-key {
      display: inline-block;
      width: 10px;
      height: 10px;
    }
</style>
