var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content_box beforetab capture_area" }, [
      _c("div", { staticClass: "clearfix" }, [
        _c("div", { staticClass: "col_6" }, [
          _c("p", { staticClass: "text_desc" }, [
            _vm._v(" " + _vm._s(_vm.$t("msg.CART010G010.068")) + " "),
          ]),
          _c("br"),
          _vm._m(0),
        ]),
      ]),
    ]),
    _c("div", { attrs: { id: "pdf_area" } }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "graph_area",
        staticStyle: { margin: "0px", width: "770px" },
      },
      [
        _c("canvas", { attrs: { id: "myChart" } }),
        _c("div", { attrs: { id: "chartjs-tooltip" } }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }